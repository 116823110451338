import type { ReactNode } from "react";

import { Box, Typography } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";

import * as styles from "./ErrorBox.styles";

interface Props {
  error: ReactNode;
  subMessage?: string;
}

const ErrorBox = ({ error, subMessage }: Props) => {
  const { phoneLarge } = useBreakpoints();
  const typographySize = phoneLarge.greaterThan ? "body" : "footnote";

  return (
    <Box
      isBordered
      isColor="danger"
      padding="xsmall"
      layoutStyles={styles.box}
      data-testid="sign-up-error-box"
    >
      <Typography isFullWidth isSecondaryWeight={!!subMessage} type={typographySize}>
        {error}
      </Typography>
      {!!subMessage && (
        <Typography isFullWidth type={typographySize}>
          {subMessage}
        </Typography>
      )}
    </Box>
  );
};

export { ErrorBox };
