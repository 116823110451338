import { useTranslation } from "react-i18next";

import type { FloatingLabelInputProps } from "@aviary";
import { FloatingLabelInput } from "@aviary";
import {
  isLengthValid,
  isEmailDotValid,
  isEmailAtValid,
} from "@shared/components/EmailInput/EmailValidation/EmailValidation";
import { useGlobalConfig } from "@unauthenticated/shared/context";
import { e2eAttributes } from "@unauthenticated/shared/e2eAttributes";
import { l } from "@unauthenticated/shared/locales/i18n";

interface Props extends Omit<FloatingLabelInputProps, "id" | "label" | "type"> {
  value: string;
  isDirty: boolean;
}

const SignInEmailInput = ({ value, isDirty, ...rest }: Props) => {
  const { t } = useTranslation();
  const { emailRegex } = useGlobalConfig();

  const getErrors = () => {
    if (!isDirty) return null;
    if (!isLengthValid(value)) {
      return t(l.common.EmailRequired);
    }
    if (!isEmailAtValid(value)) {
      return t(l.common.EmailMustHaveAt);
    }
    if (!isEmailDotValid(value)) {
      return t(l.common.EmailMustHaveDot);
    }
    if (!emailRegex.test(value)) {
      return t(l.common.EmailInvalidFormat);
    }
  };

  return (
    <FloatingLabelInput
      maxLength={255}
      data-e2e={e2eAttributes.login.signInEmail}
      type="email"
      id="email"
      label={t(l.common.Email)}
      errors={[getErrors()]}
      value={value}
      {...rest}
    />
  );
};

export { SignInEmailInput };
