const e2eAttributes = {
  login: {
    signInButton: "sign-in-button",
    signInEmail: "sign-in-email",
    signInPassword: "sign-in-password",
    createPractitionerLink: "create-practitioner-link",
    emersonLogo: "emerson-logo",
  },
  practitionerSignup: {
    createPractitionerButton: "create-practitioner-button",
    firstName: "first_name",
    lastName: "last_name",
    tosCheckbox: "tos_checkbox",
    nextButton: "next_button",
    email: "email",
    password: "password",
    practitionerTypeTrigger: "practitioner-type-trigger",
    continueWithEmail: "continue-with-email",
    fieldErrorInformation: "form-field-error-information",
  },
} as const;

export { e2eAttributes };
